/*******************
Layout Styles
*******************/

body {
  width: 95vw;
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;
}

video { max-width: 100%; }
