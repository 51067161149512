/********************
Font Declarations
********************/

@font-face {
  font-family: 'Bitter';
  src: url('/assets/fonts/bitter.woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Bitter';
  src: url('/assets/fonts/bitter-bold.woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Bitter';
  src: url('/assets/fonts/bitter-italic.woff2');
  font-weight: normal;
  font-style: italic;
}

// only used for the fancy Y
@font-face {
  font-family: 'Engagement';
  src: url('/assets/fonts/engagement.woff2');
  font-weight: normal;
  font-style: normal;
}

/********************
Typography Styles
********************/

body {
  line-height: 1.7;
  font-size: 16px;
  font-family: 'Bitter', serif;
  color: #333;
  text-align: justify;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.text-small { font-size: .8rem; }

.text-italic { font-style:  italic; }
.text-bold   { font-weight: bold;   }

.text-center { text-align: center; }

.border-top    { border-top:    2px solid $secondary; }
.border-bottom { border-bottom: 2px solid $secondary; }

.title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  text-align: left;
  hyphens: none;
}
article {
  @extend .border-bottom;

  hr { color: $code-bg; }
}

time {
  @extend .text-small;
  @extend .text-italic;

  font-weight: normal;
}

code {
  background-color: $code-bg;
  padding: .1em .25em;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
pre {
  @extend code;

  width: 80ch;
  font-size: .9em;
  padding: .4em;
  overflow-x: auto;

  code { padding: 0; }
  
  @media screen and (max-width: 80ch) { width: auto; }
}
.highlight { background-color: $code-bg !important; }
.highlight .c1 { color: #888 !important; }

.footnotes {
  @extend .text-small;
  @extend .text-italic;
  
  border-top: 1px solid;
  
  code { font-style: normal; }
}

footer {
  @extend .text-small;
  @extend .text-italic;
}

.fancy-y {
  vertical-align: top;
  font-family: 'Engagement';
}
